<template>
  <div class="steps">
  	<Stepper :currentStep="currentStep" :totalSteps="totalSteps"/>
    <div class="steps-container" v-show="currentStep === 1">
      <div class="steps--content-header mb-5">
        <h1 class="title is-2 has-text-weight-normal is-family-secondary">1. Engagement Details</h1>
      </div>
      <div class="field">
        <label class="label">Please enter the name of your client*</label>
        <div class="control">
          <input
            v-model="detail.client"
            class="input"
            type="text"
            placeholder="Enter a name"
            required
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Please enter a name for your engagement/project*</label>
        <div class="control">
          <input
            v-model="engagement.name"
            class="input"
            type="text"
            placeholder="Enter a name"
            required
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Please enter a timeframe for your engagement/project*</label>
        <div class="control">
          <input
            v-model="detail.timeframe"
            class="input"
            required
            >
        </div>
      </div>
      <div class="field">
        <label class="label">Please enter an estimate of how many hours are needed for your engagement/project*</label>
        <div class="control">
          <input
            v-model="detail.hours_estimate"
            class="input"
            required
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Please select a location for your engagement/project (if relevant)</label>
        <div class="control">
          <label class="radio">
            <input
              v-model="detail.location_type"
              @change="toggleLocation"
              type="radio"
              name="location"
              :value="kLocationTypes.VIRTUAL"
              >
            Virtual
          </label>
          <label class="radio">
            <input
              v-model="detail.location_type"
              @change="toggleLocation"
              type="radio"
              name="location"
              :value="kLocationTypes.IN_PERSON">
            In-Person
          </label>
        </div>
      </div>
      <div class="field">
        <label class="label">Enter a city/country for your engagement/project (if relevant)</label>
        <div class="control">
          <input
            :disabled="disableLocationField"
            v-model="detail.location"
            class="input"
            type="text"
            placeholder="Enter a city/country">
        </div>
      </div>
      <div class="field">
        <label class="label">Please enter the topic for your engagement/project e.g. Early research into Breast cancer treatment*</label>
        <div class="control">
          <input
            v-model="detail.topic"
            class="input"
            type="text"
            placeholder="Enter a topic"
            required
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Please select the type of engagement*</label>
        <div class="control">
          <div class="select">
            <select v-model="detail.type" required :class="{'has-text-black': detail.type !== '', 'has-text-grey-light': detail.type === ''}">
              <option value='' disabled selected>Select Type</option>
              <option
                v-for="(type, i) in kEngagementTypes"
                :key="i"
                :value="type"
              >
                {{ type | capitalize }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="field">
        <label class="label">Please describe your engagement*</label>
        <textarea
          v-model="detail.description"
          class="textarea"
          placeholder="Enter a description"
          required
        >
        </textarea>
      </div>
      <h4 class="title is-5 mt-5">Contact Information</h4>
      <div class="field">
        <label class="label">Name*</label>
        <div class="control mb-2">
          <input
            v-model="detail.contact_name"
            class="input"
            type="text"
            placeholder="Name"
            required
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Email*</label>
        <div class="control mb-2">
          <input
            v-model="detail.contact_email"
            class="input"
            type="email"
            placeholder="Email"
            required
          >
        </div>
      </div>
      <div class="field">
        <label class="label">Phone*</label>
        <div class="control mb-2">
          <input
            v-model="detail.contact_phone_number"
            class="input phone-number"
            type="tel"
            placeholder="Phone number"
            required
            >
        </div>
      </div>
    </div>
    <div class="steps-container" v-show="currentStep === 2">
      <ExpertProfileModal ref="expertProfileModal" :disableRequests="true"/>
      <div class="steps--content-header mb-5">
        <h1 class="title is-2 has-text-weight-normal is-family-secondary">2. Selected Experts</h1>
      </div>
      <div class="experts-wizard mb-4">
        <div
          v-if="engagement.expert_requests && engagement.expert_requests.length"
          class="columns columns--experts is-multiline"
        >
          <div 
            v-for="model in engagement.expert_requests"
            :key="model.id"
            class="column is-4"
          >
            <ExpertRequestCard
              :expertRequest="model"
              v-on:view="viewExpertProfile"
            />
          </div>
        </div>
        <div v-else>
          <div class="mt-3 mb-6">
            <em>No experts selected.</em>
          </div>
        </div>
      </div>
      <button
        @click="findExperts(engagement.id)"
        class="button is-primary "
      >
        {{ engagement.expert_requests && engagement.expert_requests.length  ? 'Add more experts' : 'Select Experts' }}
      </button>
    </div>
    <div class="steps-container" v-show="currentStep === 3">
      <div class="steps--content-header mb-5">
        <h1 class="title is-2 has-text-weight-normal is-family-secondary">3. Request Sent</h1>
        <div class="my-4">
          <Engagement :engagement="engagement" />
        </div>
      </div>
    </div>
    <div class="steps-border-bottom"></div>
    <div class="wizard-btns section mt-5 pt-5">
      <div class="columns">
        <div class="column is-one-quarter-tablet">
          <button
            v-if="currentStep === 2"
            @click="previousStep"
            class="button mb- is-text"
            :disabled="saving"
          >
            <span class='icon-text is-flex'>
              <span class="icon pr-4">
                <i class="fa fa-xs fa-solid fa-chevron-left"></i>
              </span>
              Previous
            </span>
          </button>
        </div>
        <div class="column has-text-right">
          <button
            v-if="currentStep < totalSteps"
            @click="cancelRequest(true, true)"
            class="button is-text"
            >
            <span class='icon-text is-flex'>
              Cancel
              <span class="icon pl-4">
                <i class="fa fa-xs fa-solid fa-chevron-right"></i>
              </span>
            </span>
          </button>
          <button
            v-if="currentStep === 1"
            @click="nextStep"
            class="button is-primary"
            :class="{ 'is-loading': saving }"
            :disabled="saving || !canContinue"
          >
            Continue
          </button>
          <button
            v-if="currentStep === 2"
            @click="submit"
            class="button is-primary"
            :class="{ 'is-loading': saving }"
            :disabled="saving"
          >
            Submit Request
          </button>
          <button
            v-if="currentStep === 3"
            @click="findExperts(null)"
            class="button is-primary "
            :disabled="saving"
          >
            Find Another Expert
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  ENGAGEMENT_ENDPOINT,
  EXPERTS_ENDPOINT,
  LOCATION_TYPES,
  ENGAGEMENT_STATES
} from '../../consts'
import Stepper from './stepper'
import ExpertRequestCard from '../expert_request_card'
import ExpertProfileModal from '../expert_profile_modal'
import Engagement from '../engagement'

const ENGAGEMENT_TYPES = [
  "speaker",
  "advisor",
  "reviewer",
  "other"
]

const DEFAULT_DETAIL = {
  client: '',
  contact_name: '',
  contact_email: '',
  contact_phone_number: '',
  date: '',
  description: '',
  hours_estimate: null,
  location: '',
  location_type: '',
  time: '',
  timeframe: '',
  topic: '',
  type: '',
  expert_requests: [],
  expert_suggestions: []
}

export default {
  components: {
    Stepper,
    ExpertRequestCard,
    ExpertProfileModal,
    Engagement
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    initialEngagement: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      currentStep: 1,
      totalSteps: 3,
      disableLocationField: true,
      saving: false,
      kEngagementTypes: ENGAGEMENT_TYPES,
      kLocationTypes: LOCATION_TYPES,
      status: '',
      engagement: { ...this.initialEngagement, ...{} },
      detail: this.initialEngagement.detail || this.getDefaultDetail(),
      findingExperts: false
    }
  },
  computed: {
    apiData() {
      let result = {
        name: this.engagement.name,
        detail: this.detail
      }
      result.detail.expert_requests = this.$refs.requests ? this.$refs.requests.models : [],
      result.detail.expert_suggestions = this.$refs.suggestions ? this.$refs.suggestions.models : []
      return result
    },
    canContinue() {
      return this.detail.client &&
             this.detail.contact_name &&
             this.detail.contact_email &&
             this.detail.contact_phone_number &&
             this.detail.description &&
             this.detail.hours_estimate &&
             this.detail.timeframe &&
             this.detail.type &&
             this.detail.topic
    }
  },
  created() {
    let searchParams = new URLSearchParams(window.location.search)
    let step = parseInt(searchParams.get("step"), 10) || 1
    if (this.engagement.status === ENGAGEMENT_STATES.SUBMITTED && step !== this.totalSteps) {
      step = this.totalSteps
      this.updateUrlSearchParamStep(step)
    }
    if (step) {
      this.currentStep = step
    }
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault()
      if (this.engagement.id && 
          this.engagement.status !== ENGAGEMENT_STATES.SUBMITTED && 
          !this.findingExperts
      ) {
        this.cancelRequest(false, false)
      }
      return undefined
    })
  },
  methods: {
    getDefaultDetail() {
      let result = Object.assign(DEFAULT_DETAIL, {})
      if (this.user) {
        result.contact_name = this.user.preferred_username
        result.contact_email = this.user.email
      }
      return result
    },
    updateUrlSearchParamStep (step) {
      let searchParams = new URLSearchParams(window.location.search)
      searchParams.set("step", step)
      var newPathQuery = window.location.pathname + '?' + searchParams.toString()
      history.pushState(null, '', newPathQuery);
    },
    findExperts(id) {
      let url = EXPERTS_ENDPOINT
      if (id) {
        url = `${url}?engagement=${id}`
        this.findingExperts = true
      }
      window.location.href = url
    },
    viewExpertProfile (expert) {
      this.$refs.expertProfileModal.expert = expert
    },
    save(submit=false) {
      this.saving = true
      this.status = 'Saving...'
      let data = this.apiData
      data.user_id = this.user.id
      const method = this.engagement.id ? 'put' : 'post'
      const url = this.engagement.id ? `${ENGAGEMENT_ENDPOINT}/${this.engagement.id}` : ENGAGEMENT_ENDPOINT
      if (submit) {
        data.status = 'submitted'
      }
      return axios({
          method,
          url,
          data: { engagement: data }
        })
        .then(async result => {
          this.engagement = { ...this.engagement, ...result.data }
          this.detail = this.engagement.detail
        })
        .finally(() => {
          this.saving = false
          this.status = ''
        })
    },
    submit() {
      this.save(true)
        .then(result => {
          this.nextStep()
        })
    },
    cancelRequest(prompt, redirect) {
      prompt = !!prompt
      redirect = !!redirect
      if (prompt) {
        confirm("Are you sure you want to cancel this request? All of its data will be lost.'")
      }
      if (this.engagement.id) {
        return axios
          .delete(`${ENGAGEMENT_ENDPOINT}/${this.engagement.id}`)
          .then(async result => {
           if (redirect) {
              window.location.href = EXPERTS_ENDPOINT
            }
          })
      }
      else {
        window.location.href = EXPERTS_ENDPOINT
      }
    },
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.save()
          .then(result => {
            this.currentStep = this.currentStep + 1
            this.updateUrlSearchParamStep(this.currentStep)
            window.scrollTo(0,0)
          })
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1
        this.updateUrlSearchParamStep(this.currentStep)
        window.scrollTo(0,0)
      }
    },
    toggleLocation() {
      this.disableLocationField = this.detail.location_type !== LOCATION_TYPES.IN_PERSON
    }
  }
}
</script>

<style scoped lang="scss">

.steps {

  @media (min-width:1024px) {
      margin-top: -25px;
  }
  @media (max-width:1024px) {
      margin-top: 25px;
  }
  @media (max-width:768px) {
      margin-top: 10px;
  }

}

.wizard-btns {

  .column.has-text-right {

    button:not(:last-child) {
      margin-right: 30px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      button:not(:last-child) {
        margin-right: 0px;
      }

    }

  }

}

.steps-border-bottom {
  border-bottom: solid 1px #B0BEC2;
	width: 75%;
  margin: auto;
}

.steps-container {
	padding: 1.5em 0em 3em;

	@media (max-width: 1024px) {
      padding-top: .5em;
  }

  input:not([type="radio"]), textarea, select {
    border-radius: 0px;
    border-color: #B0BEC2;
    background-color: #F3F2EC;
    box-shadow: none;
    @media (min-width: 1216px) {
      width: 80%
    }
  }
  select {
    width: fit-content
  }
  textarea {
    // border-radius: 20px;
    min-width: auto;
  }
  .select::after {
    border-color: #B0BEC2;
  }
  .phone-number {
    max-width: 350px;
  }

}

</style>
