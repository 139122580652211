import axios from 'axios'
import {
  ENGAGEMENTS_ENDPOINT,
} from '../consts'

const DELAY = 1800000 // 30 minutes
const MODULE_ID ="com.eh.jobs.fetch_engagements_job"

class FetchEngagementsJob {

  constructor() {
    this.interval = null
  }

  start(config) {
    this.stop()
    if ('callback' in config) {
      this.interval = setInterval(() => {
        axios.get(ENGAGEMENTS_ENDPOINT)
          .then(result => {
            config.callback(result.data)
          })
          .catch(error => {
            console.warn(`${MODULE_ID} > get engagements failed`)
            console.warn(error)
          })
      }, DELAY)
    }
  }

  stop() {
    clearInterval(this.interval)
  }
}

export default FetchEngagementsJob
