<template>
  <div
    :class="{'is-active': expert}"
    class="modal"
  >
    <div class="modal-background"></div>
    <div v-if="expert" class="modal-content">
      <div class="container p-6">
        <div class="is-flex is-justify-content-space-between">
          <div class="image-wrap--expert mb-2">
            <figure
              v-if="expert.image_url"
              class="image"
            >
              <img :src="expert.image_url" />
            </figure>
          </div>
          <button
            @click="dismiss"
            class="delete is-large ml-4"
            aria-label="close">
          </button>
        </div>
        <div class="column">
          <div v-if="expert.archived" class="mb-1">
            <span class="tag is-warning">Archived</span>
          </div>
          <h1 class="title is-2 has-text-weight-normal is-family-secondary">{{ expert.display_name }}</h1>
          <p v-html="expert.title.body" class="subtitle"></p>
          <p v-if="expert.hcp_type" class="mb-4">{{ expert.hcp_type.name }}</p>
          <p v-if="expert.specialties.length" class="mb-4">{{ specialties.join(" | ") }}</p>
          <p>{{ expert.country ? expert.country.name : "--" }}</p>
        </div>
        <div class="column is-four-fifths-desktop">
          <div v-html="expert.bio.body"></div>
          <h2 class="title is-3 mt-4 mb-2">Bio</h2>
          <div v-html="expert.bio_long.body"></div>
        </div>
        <div class="column">
          <button v-if="!disableRequests && !expertSelected" @click="add" class="button is-primary ">
            Add to request
          </button>
          <button v-if="!disableRequests && expertSelected" @click="remove" class="button is-danger ">
            Remove from Request
          </button>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disableRequests: {
      type: Boolean,
      default: true
    },
    hcpTypeDisabled: {
      type: Boolean,
      default: false
    },
    attrDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      expert: null,
      expertSelected: false    }
  },
  computed: {
    specialties() {
      return this.expert.specialties.map(s => s.name)
    }
  },
  methods: {
    dismiss () {
      this.expert = null
    },
    add () {
      this.$emit('add', this.expert)
    },
    remove () {
      this.$emit('remove', this.expert)
    }
  }
}
</script>

<style scoped lang="scss">

.modal-content {
	// height: 60%;
  background-color: #F3F2EC;

  .delete {
    background-color: #0a0a0ac4;
    }

  @media (min-width: 1024px){
    width: 852px;
  }
}
</style>
