<template>
  <div :key="expert.id" class="card card--engagement-expert">
  	<div v-if="!editable" class="status">
			<button
					class="button"
					disabled="disabled"
				>
					<span class="icon is-large">
						<i class="fa fa-lock"></i>
					</span>
			</button>
    </div>
    <div class="card-image">
      <figure class="image">
        <img :src="imageUrl">
      </figure>
    </div>
    <div class="card-content p-3">
      <div class="media">
        <div class="media-content">
          <div class="field has-addons mb-4">
            <div class="control">
              <div v-if="editable && isEditingStatus" class="select is-small">
                <select
                  v-model="requestStatus"
                  name="status">
                  <option v-for="(option, i) in states" :value="option" :key="'state_' + i">
                    {{ option | capitalize }}
                  </option>
                </select>
              </div>
              <div v-else style="height:100%;">
                <span
                  class="tag is-flex"
                  :class="statusClass"
                >
                  {{ status | capitalize }}
                </span>
              </div>
            </div>
            <div v-if="editable && isEditingStatus" class="control">
              <button
                @click="requestStatusCheck"
                class="button is-success is-small">
                Save
              </button>
            </div>
            <div v-else-if="editable" class="control">
              <button
                @click="isEditingStatus = true"
                class="button is-tag is-small">
                	<span class="icon is-small">
                    <i class="fa fa-pencil-alt"></i>
                  </span>
              </button>
            </div>
          </div>
          <p class="title">{{ expert.display_name }}</p>
          <p
            v-if="expert.hcp_type && !hcpTypeDisabled"
            class="subtitle">
            {{ expert.hcp_type.name }}
          </p>
        </div>
      </div>
      <div class="content">
        <button @click="viewProfile" class="button is-text p-0">
          <span class='icon-text'>
            View profile
            <span class="icon pl-4">
              <i class="fa fa-xs fa-solid fa-chevron-right"></i>
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  EXPERTS_ENDPOINT,
  EXPERT_REQUEST_STATES
} from '../consts'
const IMAGE_PLACEHOLDER_URL = 'https://bulma.io/images/placeholders/640x480.png'

export default {
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    expertRequest: {
      type: Object,
      default: null
    },
    hcpTypeDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isEditingStatus: false,
      requestStatus: this.expertRequest.status
    }
  },
  computed: {
    engagement () {
      return this.expertRequest.engagement
    },
    expert () {
      return this.expertRequest.expert
    },
    status () {
      return this.expertRequest.status
    },
    statusClass () {
      return {
        'is-editing': this.editable,
        'is-link': this.expertRequest.status === EXPERT_REQUEST_STATES.CONFIRMED,
        'is-primary': this.expertRequest.status === EXPERT_REQUEST_STATES.REQUESTED,
        'is-danger': this.expertRequest.status === EXPERT_REQUEST_STATES.REJECTED
      }
    },
    profileUrl () {
      return this.expert ? `${EXPERTS_ENDPOINT}/${this.expertRequest.expert_id}` : ''
    },
    imageUrl () {
      return this.expert && this.expert.image_url ? this.expert.image_url : IMAGE_PLACEHOLDER_URL
    },
    states () {
      return Object.values(EXPERT_REQUEST_STATES)
    }
  },
  methods: {
    requestStatusCheck () {
      this.isEditingStatus = false
      if (this.expertRequest.status !== this.requestStatus) {
        this.$emit("expert-request-status-change", {
          expert_request: this.expertRequest,
          status: this.requestStatus
        })
      }
    },
    viewProfile () {
      this.$emit('view', this.expert)
    }
  }
}
</script>

<style scoped lang="scss">

.status {
  top: 9px !important;

  button.button, button.button[disabled] {
    background-color: transparent !important;
    border: none;
    span i.fa-lock {
      font-size: 1.5em;
      color: #FFF200;
    }
  }

}

.control {

  .select select {
    border: none;
  }

	.tag.is-editing {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	.button.is-tag {
		// width: 27px;
		// height: 27px;
		// margin-top: 2px;
		// color: white;
    height: 100%;

		&:focus,
		&:active {
			border-color: #ccc;
			border-width: 2px;
		}

	}

}

</style>
