<template>
  <div :key="expert.id" class="card card--expert">
    <div class="card-image">
      <figure class="image">
        <img :src="imageUrl" alt="Expert Image">
      </figure>
    </div>
    <div class="card-content p-3">
      <div class="media">
        <div class="media-content">
          <div class="media-content__header-container mb-4">
            <p class="title is-spaced mb-0">{{ expert.display_name }}</p>
            <p v-html="expert.title.body" class="subtitle"></p>
            <div v-if="selectable" class="status">
              <button
                @click="toggleSelect"
                class="button is-text"
              >
                <span class="icon plus-icon" :class="{'is-active' : selected }"></span>
              </button>
            </div>
          </div>
          <p class="subtitle" v-if="expert.hcp_type && !hcpTypeDisabled">{{ expert.hcp_type.name }}</p>
          <p class="subtitle">{{ specialties.join(" | ") }}</p>
        </div>
      </div>
      <div class="content">
        <button @click="viewProfile" class="button is-text p-0">
          <span class='icon-text'>
            View profile
            <span class="icon pl-4">
              <i class="fa fa-xs fa-solid fa-chevron-right"></i>
            </span>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EXPERTS_ENDPOINT } from '../consts'
const IMAGE_PLACEHOLDER_URL = "https://bulma.io/images/placeholders/640x480.png"

export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    expert: {
      type: Object,
      default: null
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    hcpTypeDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {}
  },
  computed: {
    profileUrl () {
      return `${EXPERTS_ENDPOINT}/${this.expert.id}`
    },
    imageUrl () {
      return this.expert.image_url || IMAGE_PLACEHOLDER_URL
    },
    specialties() {
      return this.expert.specialties.map(s => s.name)
    }
  },
  methods: {
    toggleSelect () {
      this.$emit('change', !this.selected)
    },
    viewProfile () {
      this.$emit('view', this.expert)
    }
  }
}
</script>

<style scoped lang="scss"></style>
