export const MESSAGES_ENDPOINT = "/messages"
export const MESSAGES_READ_ENDPOINT = "/messages/read"
export const ENGAGEMENT_ENDPOINT = "/requests"
export const ENGAGEMENTS_ENDPOINT = "/requests"
export const EXPERTS_ENDPOINT = "/experts"
export const EXPERT_REQUESTS_ENDPOINT = "/expert-requests"
export const REQUEST_ACCESS_ENDPOINT = "/access/ask"
export const CONTACT_US_ENDPOINT = "/support/contact"

export const ENGAGEMENT_STATUS_CLASSES = {
  "created": "is-light",
  "submitted" : "is-success is-light"
}

export const ENGAGEMENT_STATES = {
  CREATED: "created",
  SUBMITTED: "submitted",
}

export const ENGAGEMENT_STATE_NAMES = {
  "created": "Created",
  "submitted": "Submitted"
}

export const USER_ROLES = {
  ADMIN: 1,
  STANDARD: 0
}

export const EXPERT_REQUEST_STATES = {
  REQUESTED: 'requested',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected'
}

export const LOCATION_TYPES = {
  NONE: "none",
  VIRTUAL: "virtual",
  IN_PERSON: "in_person"
}
