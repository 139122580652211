<template>
	<div class="stepper is-family-secondary mb-4 is-flex is-justify-content-center">
		<div class="is-mobile numbers is-flex is-justify-content-center pb-6">
			<div class="" v-for="(s,i) in totalSteps" :key="i">
				<div class="number" :class="{ 'is-active': s === currentStep, 'is-filled': currentStep > s }">{{ s }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      default: () => 1
    },
    totalSteps: {
      type: Number,
      default: () => 1
    },
    top: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

.stepper {
	position: relative;

	.numbers {
		position: relative;
		z-index: 2;
		margin-bottom: 0;
		border-bottom: solid 1px #B0BEC2;
		width: 75%;
		
		div:not(:last-child) {
			margin-right: 30px;

			@media (min-width: 500px) {
				margin-right: 60px;
			}
			@media (min-width: 1024px) {
				margin-right: 80px;
			}
		}

		.number {
			display: flex;
			width: 2em;
			min-width: 2em;
			margin: 0 auto;
			padding: 0;
			font-weight: 700;
			color: #071A2A;
			background-color: transparent;
			border: solid 1px #071A2A;
			
			&.is-active {
				color: white;
				background-color: #071A2A;
			}

			&.is-filled {
				@extend .is-active;
			}
			
		}
		  @media (max-width:1024px) {
			padding-bottom: 2rem !important;
		}
	}

}


</style>
