import axios from 'axios'

axios.interceptors.response.use(undefined, function(err) {
  var config = err.config
  if (!config || !config.retry) {
    return Promise.reject(err)
  }

  config.__retryCount = config.__retryCount || 0
  if (config.__retryCount >= config.retry) {
    return Promise.reject(err)
  }

  config.__retryCount += 1

  var backoff = new Promise(function(resolve) {
    setTimeout(() => {
      resolve()
    }, config.retryDelay || 1)
  })

  return backoff.then(() => {
    return axios(config)
  })
})
