<template>
  <div
    :class="{'is-active': active}"
    class="modal"
  >
    <div class="modal-background"></div>
    <div class="modal-card px-4">
      <header class="modal-card-head">
        <p class="modal-card-title">Select An Existing Request</p>
        <button
          @click="dismiss"
          class="delete"
          aria-label="close">
        </button>
      </header>
      <section class="modal-card-body">
        <div class="menu">
          <ul class="menu-list">
            <li>
              <a
                v-for="r in requests"
                :key="r.id"
                @click="selectedRequest = r"
                :class="{'is-active': r === selectedRequest}"
              >
                <span class="icon-text">
                  <span class="icon">
                    <i
                      :class="r === selectedRequest ? 'fa-check' : 'fa-file-alt'"
                      class="fas"></i>
                  </span>
                  <span class="name ml-1">{{ r.name }}</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button @click="select" class="button is-success">
          Select
        </button>
        <button @click="dismiss" class="button">
          Cancel
        </button>
      </footer>
    </div>
    <button @click="dismiss" class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
export default {
  props: {
    requests: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      active: false,
      selectedRequest: null
    }
  },
  methods: {
    dismiss () {
      this.active = false
      this.selectedRequest = null
    },
    select () {
      this.$emit('select', this.selectedRequest)
    }
  }
}
</script>

<style scoped lang="scss">

.modal-card {
	height: 60%;
	
	.modal-card-title {
		font-size: 1.25em;
		font-weight: 500;
	}
	
	.modal-card-body {
		padding: 0.5em 1em 1em;
		background-color: #f0f0f0;
		    
		.menu-list {
		
			li a {
				margin: 0.5em 0;
				padding: 0.75em;
				border-radius: 10px;
				background-color: white;
				text-decoration: none;
				
				span.icon {
					background-color: white;
					border-radius: 0.75em;
					color: #888;
					
					i:before {
						position: relative;
						top: -0.5px;
						left: 0.5px;
					}
					
				}
				
				span.name {
					text-decoration: underline;
				}
				
				&:hover {
					background-color: #d9d9d9;
				}
			
				&.is-active {
					background-color: #0099cc;
													
					span.icon {
						color: #0099cc;
						
						i:before {
							top: 0.5px;
							left: 0;
						}
						
					}
					
					span.name {
						text-decoration: none;
					}
				
				}
			
			}
		
		}
	
	}

}

</style>
