<template>
  <div class="find-experts-container pb-4">
    <RequestsModal
      ref="requestsModal"
      :requests="userEngagements"
      v-on:select="onSelectedRequest"
    />
    <ExpertProfileModal
      ref="expertProfileModal"
      :disableRequests="user.role !== USER_ROLES.STANDARD"
      :attrDisabled="attrDisabled"
      :hcpTypeDisabled="hcpTypesDisabled"
      v-on:add="onModalAddExpert"
      v-on:remove="onModalRemoveExpert"
    />
    <div class="columns--find-experts">
      <div class='expert-filter__header container pb-5'>
        <div class='column is-three-fifths-desktop mb-4 mt-0 p-0'>
          <h1 class="title is-1 has-text-weight-normal is-family-secondary">Find an Expert</h1>
          <p>Sponsored by the Lumanity Patient Engagement Centre of Excellence, the Lumanity Patient Council is an internal portal for all members of Lumanity. Use this platform to identify members who could act as practical or strategic advisors to support internal or external projects.</p>
          <br/>
          <p>Click on the Plus button to add an Expert to your Request. Click on the X button to remove them.</p>
        </div>
        <div
          class="expert-search">
          <div class="column is-three-fifths-desktop px-0">
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field has-addons">
                  <p class="control is-expanded">
                    <input
                      v-model.trim="searchInput"
                      class="input"
                      type="text"
                      placeholder="Search for experts"
                    >
                  </p>
                  <p class="control">
                    <a class="button is-text">
                      <span class="icon">
                        <i class="fas fa-search"></i>
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="-column--expert-filter container">
        <button @click="showFilters = !showFilters" class="button is-text p-0">
          <span class='icon-text is-flex'>
            {{ showFilters ? 'Close filters' : 'Open filters' }}
            <span class="icon pl-4">
              <i class="fa fa-xs fa-solid fa-chevron-right"></i>
            </span>
          </span>
        </button>
        <div v-if="showFilters" class="expert-filter-wrapper m-4 py-4 px-0">
          <div class="refinements">
            <p class="is-size-4 has-text-weight-medium pb-2">Filters:</p>
            <p class="is-size-6 has-text-weight-medium pb-2">Country:</p>
            <div class="field">
              <div class="select is-fullwidth is-small">
                <select v-model="filter_country">
                  <option value="">None</option>
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    v-bind:value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
            <template v-if="hcpTypes.length && !hcpTypesDisabled">
              <hr class='my-2'/>
              <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                <p class="is-size-6 has-text-weight-medium pb-2">HCP Types:</p>
                <button
                  v-if="filter_hcpType.length"
                  class="button is-small is-text"
                  @click="filter_hcpType = []"
                >
                  clear
                </button>
              </div>
              <div
                v-for="(type, i) in hcpTypes"
                :key="'hcp_' + i"
                class="field pl-5"
              >
                <input :id="'hcp_' + i" :value="type.id" type="checkbox" v-model="filter_hcpType"/>
                <label :for="'hcp_' + i">{{ type.name }}</label>
              </div>
            </template>
            <template v-if="hcpSpecialties.length">
              <hr class='my-2'/>
              <div class="is-flex is-flex-direction-row is-justify-content-space-between">
                <p class="is-size-6 has-text-weight-medium pb-2">Specialties:</p>
                <button
                  v-if="filter_hcpSpecialty.length"
                  class="button is-small is-text"
                  @click="filter_hcpSpecialty = []"
                >
                  clear
                </button>
              </div>
              <div
                v-for="(specialty, i) in hcpSpecialties"
                :key="'specialty_' + i"
                class="field pl-5"
              >
                <input :id="'specialty_' + i" :value="specialty.id" type="checkbox" v-model="filter_hcpSpecialty"/>
                <label :for="'specialty_' + i">{{ specialty.name }}</label>
              </div>
            </template>
            <template v-if="!attrDisabled">
              <hr class='my-2'/>
              <div class="field pl-5">
                <input id="attr" type="checkbox" v-model="filter_attr" />
                <label for="attr">National/Regional ATTR Centre</label>
              </div>
            </template>
          </div>
          <div class="my-4 has-text-centered">
            <button class="button is-secondary " @click="resetSearch()">Reset filter</button>
          </div>
        </div>
      </div>
      <div class="column--expert-results container py-5 px-0">
        <div
          v-if="canEditEngagement"
          class="selected-experts -is-light container p-0">
          <div v-if="engagement" class="">
              <p style="font-style:italic;">Editing Experts for <strong>{{ engagement.name }}</strong></p>
          </div>
          <div class="selected-experts--container columns is-desktop py-4 is-flex-wrap-wrap">
            <div class="selected-experts--field column is-three-fifths-desktop pl-3">
              <div class="selected-experts--field-names is-flex is-align-items-center px-3">
                <p v-if="selectedExpertIDs.length === 0" class="has-text-grey-light">Your experts</p>
                <template v-else>
                  <ul class="is-flex is-flex-wrap-wrap is-align-items-center">
                    <li
                      v-for="(expert, i) in selectedExpertNames"
                      :key="'expert_' + i"
                      class="is-flex is-align-items-center"
                    >
                      {{ expert.name }}
                      <button
                        @click="selectedExpertMap[expert.id] = false"
                        class="button is-text"
                        style="height:fit-content;"
                      >
                        <span class="icon plus-icon is-active"></span>
                      </button>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
            <div
              v-if="engagement"
              class="selected-experts--btns buttons column is-flex-wrap-nowrap"
              >
              <button
                v-if="initialEngagement"
                @click="cancelEngagementEdit"
                class="button is-primary "
              >
                Cancel
              </button>
              <button
                v-else
                @click="cancelEngagementEdit"
                class="button is-primary "
              >
                Cancel
              </button>
              <button
                @click="addToExistingEngagement"
                class="button is-primary "
              >
                Save
              </button>
            </div>
            <div
              v-else
              class="selected-experts--btns buttons column is-flex-wrap-nowrap"
              >
              <button
                v-if="user.role === USER_ROLES.STANDARD"
                @click="addToNewEngagement"
                class="button is-primary "
                :disabled="selectedExpertsTotal === 0"
              >
                Add to a New Request
              </button>
            </div>
          </div>
        </div>
        <div class="columns is-multiline is-variable is-4 py-4">
          <template v-for="(e,i) in filtered_experts">
            <div
              v-if="(selectedOnly && selectedExpertMap[e.id]) || !selectedOnly"
              :key="'selected_' + i"
              class="column is-one-third"
            >
              <ExpertRequestCard
                v-if="engagement && engagement.status !== kEngagementStates.CREATED && getExpertRequestByExpertId(e.id)"
                :expertRequest="getExpertRequestByExpertId(e.id)"
                :hcpTypeDisabled="hcpTypesDisabled"
              />
              <ExpertCard
                v-else
                v-model="selectedExpertMap[e.id]"
                :expert="e"
                :selectable="canEditEngagement"
                :hcpTypeDisabled="hcpTypesDisabled"
                v-on:view="viewExpertProfile"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ExpertCard from './expert_card'
import ExpertRequestCard from './expert_request_card'
import RequestsModal from './requests_modal'
import ExpertProfileModal from './expert_profile_modal'
import {
  EXPERTS_ENDPOINT,
  ENGAGEMENT_ENDPOINT,
  ENGAGEMENT_STATES,
  USER_ROLES
} from '../consts'

const ENGAGEMENT_STEPS = {
  DETAIL: 1,
  SELECT_EXPERTS: 2,
  EXPERT_INFO: 3,
  SUBMIT: 4
}

const SEARCH_TEXT_DEBOUNCE = 500

function makeSelectedList(experts, expert_requests) {
  let result = {}
  experts.forEach(e => {
    result[e.id] = expert_requests.indexOf(e.id) !== -1
  })
  return result
}

export default {
  components: { ExpertCard, ExpertRequestCard, RequestsModal, ExpertProfileModal },
  props: {
    user: {
      type: Object,
      default: null
    },
    initialEngagement: {
      type: Object,
      default: null
    },
    userEngagements: {
      type: Array,
      default: () => []
    },
    experts: {
      type: Array,
      default: () => []
    },
    countries: {
      type: Array,
      default: () => []
    },
    hcpTypes: {
      type: Array,
      default: () => []
    },
    hcpSpecialties: {
      type: Array,
      default: () => []
    },
    hcpTypesDisabled: {
      type: Boolean,
      default: false
    },
    attrDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      USER_ROLES,
      kEngagementStates: ENGAGEMENT_STATES,
      selectable: true,
      saving: false,
      selectedExpertMap: [],
      selectedOnly: false,
      showRequestsModal: false,
      engagement: this.initialEngagement,
      filter_attr: false,
      filter_text: '',
      filter_hcpType: [],
      filter_hcpSpecialty: [],
      filter_country: '',
      searchDebounceTimeout: null,
      showFilters: false
    }
  },
  created() {
    this.reconcileSelectedExpertMap()
  },
  computed: {
    canEditEngagement () {
      const isStandardRole = this.user.role === USER_ROLES.STANDARD
      return (this.initialEngagement && !isStandardRole) || isStandardRole
    },
    searchInput: {
       get() {
         return this.filter_text
       },
       set(val) {
         clearTimeout(this.searchDebounceTimeout)
         this.searchDebounceTimeout = setTimeout(() => {
           this.filter_text = val
         }, SEARCH_TEXT_DEBOUNCE)
      }
    },
    filtered_experts () {
      let result = this.experts
      if (this.filter_attr) {
        result = result.filter(e => e.attr_centre)
      }
      if (this.filter_hcpType.length) {
        result = result.filter(e => this.filter_hcpType.indexOf(e.hcp_type_id) > -1)
      }
      if (this.filter_hcpSpecialty.length) {
        result = result.filter(e => {
          const expert_specialty_ids = e.specialties.map(s => s.id)
          const intersect = this.filter_hcpSpecialty.filter(id => expert_specialty_ids.includes(id))
          return intersect.length
        })
      }
      if (this.filter_country) {
        result = result.filter(e => this.filter_country === e.country_id)
      }
      if (this.filter_text && this.filter_text.length > 1) {
        result = result.filter(e => {
          const foundDisplayName = e.display_name.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1
          const foundSpecialty = e.specialties.filter(i => i.name.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1).length > 0
          const foundHcpType = e.hcp_type.name.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1
          const foundCountry = e.country.name.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1
          const foundBioMatch = e.bio.body.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1
          const foundBioLongMatch = e.bio_long.body.toLowerCase().indexOf(this.filter_text.toLowerCase()) != -1
          return foundDisplayName || foundSpecialty || foundHcpType || foundCountry || foundBioMatch || foundBioLongMatch
        })
      }
      return result
    },
    selectedExpertsTotal () {
      return Object.values(this.selectedExpertMap).filter(v => v).length
    },
    engagementExpertIDs () {
      let valid = this.engagement && this.engagement.expert_requests
      return valid ? this.engagement.expert_requests.map(e => e.expert_id) : []
    },
    selectedExpertIDs () {
      let ids = []
      for (const key in this.selectedExpertMap) {
        if (this.selectedExpertMap[key]) {
          ids.push(key)
        }
      }
      return ids
    },
    selectedExpertNames () {
      let result = []
      if (this.selectedExpertIDs.length > 0) {
        for(let id of this.selectedExpertIDs) {
          result = [...result, ...this.experts.filter(expert => expert.id === Number(id))]
        }
      }
      return result.map(expert => ({name: expert.display_name, id: expert.id}))
    },
    expertsString () {
      return this.selectedExpertsTotal === 1 ? 'expert' : 'experts'
    }
  },
  methods: {
    getExpertRequestByExpertId (id) {
      let model = this.engagement.expert_requests.find(e => e.expert_id === id)
      return model
    },
    getExpertProfileUrl (expert) {
      return `${EXPERTS_ENDPOINT}/${expert.id}`
    },
    redirectToEngagment (id, step) {
      window.location.href = `${ENGAGEMENT_ENDPOINT}/${id}/edit?step=${step}`
    },
    cancelEngagementEdit () {
      if (this.initialEngagement) {
        this.redirectToEngagment(this.engagement.id, ENGAGEMENT_STEPS.SELECT_EXPERTS)
      }
      else {
        this.engagement = null
        this.reconcileSelectedExpertMap()
      }
    },
    addToNewEngagement () {
      this.saving = true
      let data = {
        user_id: this.user.id,
        expert_ids: this.selectedExpertIDs
      }
      return axios
        .post(`${ENGAGEMENT_ENDPOINT}`, { engagement: data })
        .then(async result => {
          this.redirectToEngagment(result.data.id, ENGAGEMENT_STEPS.DETAIL)
        })
        .catch(error => {
        })
        .finally(() => {
          this.sending = false
          this.status = ''
        })
    },
    addToExistingEngagement () {
      this.saving = true
      let data = {
        user_id: this.user.id,
        expert_ids: this.selectedExpertIDs
      }
      return axios
        .put(`${ENGAGEMENT_ENDPOINT}/${this.engagement.id}`, { engagement: data })
        .then(async result => {
          this.redirectToEngagment(result.data.id, ENGAGEMENT_STEPS.SELECT_EXPERTS)
        })
        .catch(error => {
        })
        .finally(() => {
          this.sending = false
          this.status = ''
        })
    },
    reconcileSelectedExpertMap () {
      this.selectedExpertMap = makeSelectedList(this.experts, this.engagementExpertIDs)
    },
    onSelectedRequest (r) {
      this.engagement = r
      this.reconcileSelectedExpertMap()
      this.$refs.requestsModal.active = false
    },
    onModalAddExpert (expert) {
      this.selectedExpertMap[expert.id] = true
      this.$refs.expertProfileModal.expertSelected = true
    },
    onModalRemoveExpert (expert) {
      this.selectedExpertMap[expert.id] = false
      this.$refs.expertProfileModal.expertSelected = false
    },
    viewExpertProfile (expert) {
      this.$refs.expertProfileModal.expert = expert
      this.$refs.expertProfileModal.expertSelected = this.selectedExpertMap[expert.id]
    },
    resetSearch () {
      this.filter_attr = false
      this.filter_text = ''
      this.filter_country = ''
      this.filter_hcpType = []
      this.filter_hcpSpecialty = []
    }
  }
}
</script>

<style scoped lang="scss">

// @import "../stylesheets/colors.scss";


.find-experts-container {

  .columns--find-experts {

    // @media (max-width: 1024px) {
    //   padding-left: 1.5rem;
    //   padding-right: 1.5rem;
    // }
    // @media (max-width: 485px) {
    //   padding-left: .75rem;
    //   padding-right: .75rem;
    // }

    .expert-filter__header {

      .expert-search {

        input {
          // border-top-left-radius: 70px;
          // border-bottom-left-radius: 70px;
          border: none;
          border-radius: 0px;
          box-shadow: none;
        }
        a {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 00px;
        }

        .field.has-addons {
          // border: solid #00000021;
          border-radius: 0px;

          input {
            border: solid #00000021;
            border-right: none;
          }

          a {
            border: solid #00000021;
            border-left: none;
          }
        }
        .fa-search {
          opacity: .5;
        }
  }

    }

    .column--expert-filter {
      display: flex;
      flex-direction: column;

      .expert-filter-wrapper {
        flex: 1;
        border: 1px solid #999;
      }

    }

    .column--expert-results {

      .column {
        display: flex;
        // justify-content: center;
      }

      .selected-experts {
        .selected-experts--container {

          .selected-experts--field {
              @media (min-width:1024px){
                padding-right: 0;
              }

            .selected-experts--field-names {
              width: 100%;
              min-height: 40px;
              border: solid #00000021;
              border-radius: 0px;

              ul {
                height: 100%;

                li {
                  .plus-icon {
                    font-family: icons;
                    border: solid #071A2A;
                    border-radius: 50%;
                    padding: 1em;
                    font-size: 0.75em;

                    &::before {
                      content: "\f118";
                    }

                    &.is-active {
                      transform: rotate(45deg);
                      color: #fff;
                      background-color: #071A2A;
                    }

                  }
                }

                li:not(:last-child){
                  padding-right: 20px;

                }
              }
            }

          }

          .selected-experts--btns {
            @media (max-width: 768px) {

              button {
                width: 100%;
              }

            }
            @media (max-width: 505px) {
              flex-wrap: wrap !important;

              .button:not(:last-child) {
                margin-right: 0;
              }

            }
          }

        }
      }
    }

  }

}

/* use font awesome icons for input checkbox */

label.label {
  font-size: 1.25em;
  font-weight: 600;
}

.field label {
  line-height: 1.25;
  text-indent: -1.25em;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox] + label:before {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-size: 1.125em;
  color: #003366;
  letter-spacing: 5px;
  cursor: pointer;
}

input[type=checkbox] + label:before {
  content: '\f0c8';
}

input[type=checkbox]:checked + label:before {
  content: '\f14a';
}

</style>
