<template>
  <div class="engagement">
    <h4 class="title is-5 mb-1">Client Name</h4>
    <p>{{ detailValueFromKey('client', 'No client') }}</p>
    <h4 class="title is-5 mb-1 mt-4">Engagement Name</h4>
    <p>{{ engagement.name }}</p>
    <h4 class="title is-5 mb-1 mt-4">Timeframe</h4>
    <p>{{ detailValueFromKey('timeframe', 'No timeframe') }}</p>
    <h4 class="title is-5 mb-1 mt-4">Hours Estimate</h4>
    <p>{{ detailValueFromKey('hours_estimate', 'No hours estimate') }}</p>
    <h4 class="title is-5 mb-1 mt-4">Location Type</h4>
    <p>{{ locationType }}</p>
    <h4 class="title is-5 mb-1 mt-4">Location</h4>
    <p>{{ detailValueFromKey('location', 'No location') }}</p>
    <h4 class="title is-5 mb-1 mt-4">Topic</h4>
    <p>{{ detailValueFromKey('topic', 'No topic') }}</p>
    <h4 class="title is-5 mb-1 mt-4">Type</h4>
    <p>{{ detailValueFromKey('type', 'No type') }}</p>
    <h4 class="title is-5 mb-1 mt-4">Description</h4>
    <div class="content trix-content">
      {{ detailValueFromKey('description', 'No description') }}
    </div>
    <h4 class="title is-5 mb-1 mt-4">Contact Information</h4>
    <p><em>Name</em>: {{ detailValueFromKey('contact_name', '') }}</p>
    <p><em>Email</em>: {{ detailValueFromKey('contact_email', '') }}</p>
    <p><em>Phone Number</em>: {{ detailValueFromKey('contact_phone_number', '') }}</p>
    <h4 class="title is-5 mb-3 mt-4">Selected Experts</h4>
    <div class="experts-wizard experts-wizard--small">
      <div v-if="engagement.expert_requests.length" class="columns is-multiline">
        <div 
          v-for="(model, i) in engagement.expert_requests"
          :key="'request_' + i"
          class="column is-4" 
        >
          <ExpertRequestCard
            :key="model.id"
            :expertRequest="model"
            v-on:view="viewExpertProfile"
          />
        </div>
      </div>
      <div v-else>
        <em>No experts</em>
      </div>
    </div>
    <ExpertProfileModal ref="expertProfileModal" :disableRequests="true"/>
  </div>
</template>

<script>
import ExpertRequestCard from './expert_request_card'
import ExpertProfileModal from './expert_profile_modal'
import {
  ENGAGEMENT_STATES,
  LOCATION_TYPES
} from '../consts'

export default {
  components: { ExpertRequestCard, ExpertProfileModal},
  props: {
    engagement: {
      default: null,
      type: Object
    }
  },
  data: function () {
    return {
      kEngagementStates: ENGAGEMENT_STATES
    }
  },
  computed: {
    expertRequests () {
      let value = this.detailValueFromKey('expert_requests', null)
      return value && value.length ? value : null
    },
    expertSuggestions () {
      let value = this.detailValueFromKey('expert_suggestions', null)
      return value && value.length ? value : null
    },
    locationType () {
      let result = this.detailValueFromKey('location_type', 'None')
      if (result === LOCATION_TYPES.VIRTUAL) {
        result = 'Virtual'
      }
      else if(result === LOCATION_TYPES.IN_PERSON) {
        result = 'In Person'
      }
      return result
    }
  },
  methods: {
    detailValueFromKey(key, default_value) {
      let value = this.engagement.detail && this.engagement.detail[key]
      return value || default_value
    },
    viewExpertProfile (expert) {
      this.$refs.expertProfileModal.expert = expert
    }
  }
}

</script>
