<template>
  <div class="engagements container">
      <div class="column--engagement-list">
        <div class="menu">
          <div v-for="(e, i) in submittedEngagements" :key="i">
            <ul class="menu-list">
            <li :key="e.id">
              <a 
                class="is-flex is-align-items-center is-justify-content-space-between"
                @click="setSelectedEngagement(e)"
              >
                <div>
                  <div class="title is-3 has-text-weight-normal is-family-secondary">{{ e.name }}</div>
                  <ul 
                    v-if="user.role !== kUserRoles.STANDARD"
                    class="engagement--list-details pt-5 is-flex-desktop"
                  >
                    <li>Submitted by: {{ e.detail.contact_name }}</li>
                    <li>Email: {{ e.detail.contact_email }}</li>
                  </ul>
                </div>
                <div>
                	<button
                    class="button is-text"
                  >
                    <span 
                      class="icon plus-icon"
                      :class="{'is-active' : selectedEngagement.id === e.id & selectedEngagement.showDetails}"
                    ></span>
                  </button>
                </div>
              </a>
            </li>
          </ul>
            <div v-show="selectedEngagement.id === e.id && selectedEngagement.showDetails">
            <div class="section pt-4">
              <Engagement
                :engagement="selectedEngagement"
                v-on:expert-request-status-change="onExpertRequestStatusChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Engagement from './engagement'
import {
  ENGAGEMENT_ENDPOINT,
  EXPERT_REQUESTS_ENDPOINT,
  USER_ROLES,
  ENGAGEMENT_STATES
} from '../consts'

import FetchEngagementsJob from '../jobs/fetch_engagements_job'

export default {
  components: { Engagement },
  props: {
    user: {
      default: null,
      type: Object
    },
    initialEngagements: {
      default: () => [],
      type: Array
    }
  },
  data: function () {
    return {
      kUserRoles: USER_ROLES,
      engagements: this.initialEngagements,
      selectedEngagement: this.initialEngagements.length ? this.initialEngagements[0] : null,
      saving: false
    }
  },
  computed: {
    submittedEngagements() {
      return this.engagements.filter(e => e.status === ENGAGEMENT_STATES.SUBMITTED)
    }
  },
  created() {
    new FetchEngagementsJob().start({callback: this.engagementsJobHandler})
    let searchParams = new URLSearchParams(window.location.search)
    let id = parseInt(searchParams.get("id"), 10)
    let tab = parseInt(searchParams.get("tab"), 10)
    if (id) {
      let engagement = this.engagements.find(e => e.id === id)
      if (engagement) {
        this.selectedEngagement = engagement
      }
    }
    if (!isNaN(tab)) {
      this.selectedTabView = tab
    }
  },
  methods: {
    updateUrlSearchParams () {
      let searchParams = new URLSearchParams(window.location.search)
      this.selectedEngagement.showDetails === false
      ? searchParams.delete("id")
      : searchParams.set("id", this.selectedEngagement.id)
      var newPathQuery = window.location.pathname + '?' + searchParams.toString()
      history.pushState(null, '', newPathQuery);
    },
    setSelectedEngagement (e) {
      if(this.selectedEngagement.id !== e.id) this.selectedEngagement = e
      this.selectedEngagement = { ...this.selectedEngagement, showDetails: !this.selectedEngagement.showDetails }
      this.updateUrlSearchParams()
    },
    editEngagement (engagement) {
      window.location.href= `${ENGAGEMENT_ENDPOINT}/${engagement.id}/edit`
    },
    cancelEngagement () {
      confirm("Are you sure you want to cancel this request? All of its data will be lost.'")
      return axios
        .delete(`${ENGAGEMENT_ENDPOINT}/${this.selectedEngagement.id}`)
        .then(async result => {
          window.location.href = ENGAGEMENT_ENDPOINT
        })
        .catch(error => {
        })
    },
    saveSelectedEngagementStatus (i) {
      this.saving = true
      let state = this.$refs.engagementStatuses[i].value
      let url = `${ENGAGEMENT_ENDPOINT}/${this.selectedEngagement.id}/status`
      return axios
        .put(url, {
          engagement: {
            status: state
          }
        })
        .then(result => {
          this.selectedEngagement = {...this.selectedEngagement, status: state}
          this.engagements[i] = this.selectedEngagement
        })
        .catch(error => {
        })
        .finally(() => {
          this.saving = false
        })
    },
    saveExpertRequestStatus (expert_request, status) {
      this.saving = true
      let url = `${EXPERT_REQUESTS_ENDPOINT}/${expert_request.id}`
      return axios
        .put(url, {
          expert_request: {
            status: status
          }
        })
        .then(result => {
          let stale_request = this.selectedEngagement.expert_requests.find(e => e.id === expert_request.id)
          stale_request.status = status
        })
        .catch(error => {
        })
        .finally(() => {
          this.saving = false
        })
    },
    engagementsJobHandler (engagements) {
      this.engagements = engagements
    },
    onExpertRequestStatusChange (value) {
      this.saveExpertRequestStatus(value.expert_request, value.status)
    }
  }
}
</script>

<style scoped lang="scss">

.button:focus:not(:active), .button.is-focused:not(:active) {
  box-shadow: none;
}

.engagements {
  overflow: hidden;
  max-height: 100%;
  min-height: 100%;

  .column--engagement-list {
  	margin-bottom: 1em;

  	.menu {

  		.menu-list {
        background-color: #F3F2EC;

				li {
					position: relative;

					a {
						padding: 2.75em;
						text-decoration: none;
						border-top: 1px solid #0000004D;
            transition: 200ms;
            @media (max-width: 768px) {
              padding: 2.25em
            }
            @media (max-width: 500px) {
              padding: 1.5em
            }

						.menu-list-item--flags {
							display: flex;
							justify-content: space-between;

							.is-status {
								font-weight: 500;

								&.is-light {
									color: #0099cc;
									background-color: #eee;
								}

								&.is-success.is-light {
									color: #48c774;
								}

							}

							.is-message {
								display: block;
								padding-top: 3px;
							}

						}

						.icon-container {
							display: none;
						}

            .plus-icon {
              font-family: icons;
              border: solid #071A2A;
              border-radius: 50%;
              padding: 1em;
              transition: 200ms;

              &::before {
                content: "\f118";
              }
              
              &.is-active {
                transform: rotate(45deg);
                color: #fff;
                background-color: #071A2A;
              }

            }

						&:hover {
							background-color: darken(#F3F2EC, 5%);
              border-top: 1px solid #071A2A;

              .plus-icon {
                color: #fff;
                background-color: #071A2A;
              }
						}

						&.is-active {
							background-color: #0099cc;
							border-top: 1px solid #0099cc;
							border-right: 1px solid #0099cc;
							border-left: 1px solid #0099cc;

							.menu-list-item--flags {

								.is-message {
									display: none;
								}

							}

							.icon-container {
								display: flex;
								flex-direction: column;
								justify-content: center;
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
							}

						}

            .engagement--list-details {
              
              li:not(:last-child) {
                
                @media (min-width: 1024px) {
                  margin-right: 55px;
                }
                
              }

            }

					}

				}

			}

  	}

  }

  .column--engagement-details {
		border-right: 1px solid #dbdbdb;
		border-bottom: 1px solid #dbdbdb;
		border-left: 1px solid #dbdbdb;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;

  	.tabs {

  		li {
  			width: 50%;

  			a {
					font-weight: 600;
					color: #0099cc;
					text-decoration: none;
					border-color: #dbdbdb;

					.is-message {
						padding-bottom: 2px;
					}

  			}

				&.is-active a {
					color: white;
					background-color: #0099cc;
					border-color: #0099cc;
				}

				&:last-child a {
					border-right: 0;
				}

  		}

  	}

  }

  @media screen and (min-width: 768px) {

		.column--engagement-list {
			margin-bottom: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			.menu {

				.menu-list {

					li {
					}

					&:last-child li {
						border-bottom: 1px solid #0000004D;
					}

				}

			}

		}

		.column--engagement-details {
			min-height: 1250px;
			border-bottom-left-radius: 0;
		}

	}

}

</style>
