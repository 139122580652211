import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import '../bulma/notification.js'
import '../utils/direct_uploads.js'
import '../utils/navbar_burger.js'

import * as Trix from "trix"
require("trix/dist/trix.css")
require("@rails/actiontext")

// block level tag
Trix.config.blockAttributes.default.tagName = 'p'
// remove file drag-drop from trix editor
document.addEventListener("trix-file-accept", function(event) {
  event.preventDefault()
})

import Vue from 'vue'
import FindExpertsApp from '../components/find_expert'
import Engagements from '../components/engagements'
import EngagementWizard from '../components/engagement_wizard/wizard'
import '../initializers/axios'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

document.addEventListener('DOMContentLoaded', () => {

  // tabs
  const tabs = document.querySelectorAll('.tabs li')
  if (tabs) {
    tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {
        e.preventDefault()
        const currentTab = document.querySelector('.tabs li.is-active')
        const currentContent = document.querySelector('.tab-content div.is-active')
        const content_id = e.currentTarget.dataset.content
        const newTabContent = document.querySelector(content_id)

        currentTab.classList.remove('is-active')
        currentContent.classList.remove('is-active')
        e.currentTarget.classList.add('is-active')
        newTabContent.classList.add('is-active')
      })
    })
  }

  // add find_expert view
  const find_experts_el = document.getElementById('find-experts-app')
  if (find_experts_el) {
    const user = JSON.parse(find_experts_el.dataset.user)
    const experts = JSON.parse(find_experts_el.dataset.experts)
    const engagement = JSON.parse(find_experts_el.dataset.engagement)
    const userEngagements = JSON.parse(find_experts_el.dataset.userEngagements)
    const countries = JSON.parse(find_experts_el.dataset.countries)
    const hcpTypes = JSON.parse(find_experts_el.dataset.hcpTypes)
    const hcpSpecialties = JSON.parse(find_experts_el.dataset.hcpSpecialties)
    const hcpTypesDisabled = find_experts_el.dataset.hcpTypesDisabled === 'true'
    const attrDisabled = find_experts_el.dataset.attrDisabled === 'true'
    new Vue({
      el: find_experts_el,
      render: createElement => {
        return createElement(FindExpertsApp,
          {
            props: {
              'user': user,
              'experts': experts,
              'initialEngagement': engagement,
              'userEngagements': userEngagements,
              'countries': countries,
              'hcpTypes': hcpTypes,
              'hcpSpecialties': hcpSpecialties,
              'hcpTypesDisabled': hcpTypesDisabled,
              'attrDisabled': attrDisabled
            }
          }
        )
      }
    })
  }

  // add engagements view
  const engagements_el = document.getElementById('engagements-app')
  if (engagements_el) {
    const user = JSON.parse(engagements_el.dataset.user)
    const engagements = JSON.parse(engagements_el.dataset.engagements).map(e=>({ ...e, showDetails: false }))
    new Vue({
      el: engagements_el,
      render: createElement => {
        return createElement(Engagements,
          {
            props: {
              'user': user,
              'initialEngagements': engagements
            }
          }
        )
      }
    })
  }

  // add engagement wizard view
  const engagement_wizard_el = document.getElementById('engagement-wizard-app')
  if (engagement_wizard_el) {
    const user = JSON.parse(engagement_wizard_el.dataset.user)
    const engagement = JSON.parse(engagement_wizard_el.dataset.engagement)
    document.querySelector('.content-container').style.backgroundColor = '#F3F2EC';
    document.body.style.backgroundColor = '#F3F2EC';
    new Vue({
      el: engagement_wizard_el,
      render: createElement => {
        return createElement(EngagementWizard,
          {
            props: {
              'initialEngagement': engagement,
              'user': user
            }
          }
        )
      }
    })
  }

  const $cryptedmails = document.querySelectorAll('.cryptedmail')

  if ($cryptedmails.length) {
    $cryptedmails.forEach(el => {
      el.addEventListener('click', () => {
        const mailto = `mailto:${el.dataset.name}@${el.dataset.domain}.${el.dataset.tld}`
        window.location.href = mailto
        return false;
      })
    })
  }
})

Rails.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
